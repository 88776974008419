import React, {
  useState,
  useEffect,
  FunctionComponent,
  ReactNode,
  useRef,
} from "react";
import CustomisedTableWrapper from "./styled";
// @ts-ignore
import _, { get as getByDot } from "lodash";
import { useTranslation } from "react-i18next";
import { CONSTANTS } from "common/constants";
import { CONSTANTS as CUSTOM_REPORT_CONSTANTS } from "common/constants/customReports";
import classNames from "classnames";
import {
  DateFormat,
  DateWithTimeByLocale,
  DateFullFormat,
} from "common/utils/dateBasedOnLocale";
import SelectAll from "components/CustomisedTable/SelectAll";
import { Action } from "./action";

import ModalPopupComp from "../../components/ModalPopup/index";
import { ModalHeader } from "../../components/ModalPopup/ModalHeader";

import JsonDiff from "../../common/utils/customJSONCompare";
import { ReportLogDiff } from "./components/ReportLogDiff";
import {
  camelToNormalCase,
  convertToLocalTime,
} from "common/utils/commonFunctionality";
import { LocalStorageKeys } from "common/constants/localStorage";
import { getErrorTypeName } from "common/utils/customReportUtils";
import AuiIcon from "components/IconComponent/AuiIcon";
import AuditLogBody from "pages/AuditLog/AuditLogPopUpBody";
import VendorLogModalBody from "pages/VendorLog/VendorLogPopUpBody";

export type DateMethodFn = (
  propertyValue: string,
  propertyName: string,
  content: { [key: string]: any },
  column: { [key: string]: any }
) => any;

export interface TableColumnsInterface {
  name: string;
  propertyName: string;
  isSession?: boolean;
  isChecked: boolean;
  mandatory: boolean;
  isDate?: boolean;
  combineWithColumnName?: string;
  class: string;
  dateMethod?: Function;
  isSortable?: boolean;
  sortable?: {
    asc: string;
    desc: string;
  };
  dateFormat?: string;
}

export interface ActionProp {
  add?: boolean;
  view?: boolean;
  edit?: boolean;
  delete?: boolean;
  restore?: boolean;
  download?: boolean | ((content: any) => boolean);
}

export type ViewContentFn = (id: number) => any;
export type DownloadContentFn = (id: number) => any;
export type EditContentFn = (id: number) => any;

export interface CustomisedTableProps {
  columnFields: any[];

  /** List of contents to used to render the table */
  contents: any[];

  /** Action to perform on clicking the View btn in action */
  viewContent?: ViewContentFn;
  downloadContent?: DownloadContentFn;
  editContent?: EditContentFn;

  /** Mode: view, edit, add, or edit? */
  actions?: ActionProp;
  onSortChangeHandler?: Function;
  tableLoader: boolean;
  isActiveToggle: Function;
  cme_pageID?: string;
  enableRows?: boolean;
  isCheckBox?: boolean;
  delete?: boolean;
  restore?: boolean;
  checkBxList?: Array<number>;
  setCheckBxList?: Function;

  /** Only show text for the icon */
  iconTextOnly?: boolean;
  deleteRecords?: Function;
  MsgBody?: Function;
  classifications?: any[];
  certificates?: any[];
  children: ReactNode;
}

export const CustomisedTable: FunctionComponent<CustomisedTableProps> = ({
  columnFields,
  contents,
  viewContent,
  downloadContent,
  children,
  editContent,
  actions,
  onSortChangeHandler,
  tableLoader = false,
  isActiveToggle,
  cme_pageID,
  iconTextOnly,
  enableRows,
  isCheckBox = false,
  checkBxList = [],
  setCheckBxList = () => undefined,
  deleteRecords,
  MsgBody,
  classifications,
  certificates,
}) => {
  let isColumnJoin = false;
  const [auditLogData, setAuditLogData] = useState<any>();
  const [vendorLogErrorData, setVendorLogErrorData] = useState<any>();

  const { t } = useTranslation();
  const [columns, setColumns] = useState<TableColumnsInterface[]>(columnFields);
  const [hideVendorFilter, setHideVendorFilter] = useState(false);

  const [loader] = useState("aui-responsive-status-table noStyle-list");

  const [records, setRecord] = useState(contents);
  useEffect(() => {
    setRecord(contents);
    setColumns(columnFields);
    if (localStorage.getItem(LocalStorageKeys.ROLE) === "VENDOR_ADMIN") {
      setHideVendorFilter(true);
    }
  }, [contents, columnFields]);

  const onStatusToggle = (event: any, index: number, name: string) => {
    event.currentTarget.removeAttribute("aria-label");
    event.target.checked === true
      ? event.currentTarget.setAttribute("aria-label", t<string>("deactivate"))
      : event.currentTarget.setAttribute("aria-label", t<string>("activate"));
    const recordsCopy = [...records];
    recordsCopy[index].isActive = Boolean(event.target.checked);
    setRecord(recordsCopy);
    isActiveToggle(event.target.id, event.target.checked, name);
  };

  const activeClass = (content: any) => {
    return {
      "aui-table-status-red":
        //if it's a success activities then by default red border
        cme_pageID === CONSTANTS.SUCCESS_ACTIVITY ||
        cme_pageID === CONSTANTS.ERRORLIST_PAGE
          ? true
          : /* if isActive field does not exist or is truthy */
            content.isActive == null || content.isActive || enableRows,
      "aui-table-status-grey":
        cme_pageID === CONSTANTS.SUCCESS_ACTIVITY ||
        cme_pageID === CONSTANTS.ERRORLIST_PAGE
          ? false
          : !content.isActive && !enableRows,
    };
  };

  const mapBoolean = (content: any, column: any) => {
    if (
      typeof cme_pageID !== undefined &&
      cme_pageID === CONSTANTS.ACCREDITATION_PAGEID
    ) {
      if (getByDot(content, column?.propertyName)) {
        return t<string>("yes");
      } else {
        return t<string>("no");
      }
    } else {
      if (getByDot(content, column?.propertyName)) {
        return "true";
      } else {
        return "false";
      }
    }
  };

  const stripHtml = (str: any) => {
    if (typeof str === "string") {
      return str.replace(/(<([^>]+)>)/gi, "");
    }
    return str;
  };

  const getAddressInfo = (value: any) => {
    const NA = "N/A";
    const address = value ? JSON.parse(value) : {};

    return (
      <>
        <div className="aui-td">
          {Object.keys(address).length === 0 ? (
            NA
          ) : (
            <table>
              <tbody>
                {Object.entries(address).map(([key, value]: any) => {
                  return (
                    <tr key={key} style={{ marginBottom: "10px" }}>
                      <td>{_.startCase(key)}</td>
                      <td style={{ paddingLeft: "10px", paddingRight: "10px" }}>
                        {"  :  "}
                      </td>
                      <td>{value || NA}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}
        </div>
      </>
    );
  };

  const getAddressMobileView = (value: any) => {
    const NA = "N/A";
    const address = value ? JSON.parse(value) : {};

    return (
      <>
        {Object.keys(address).length !== 0 &&
          Object.entries(address).map(([k, v]: any) => {
            return (
              <td data-title={_.startCase(k)} className="show-mobile" key={k}>
                <div className="aui-td">{v || NA}</div>
              </td>
            );
          })}
      </>
    );
  };

  const navigateToListPage = () => undefined;

  const modalPopupProps = {
    popupDiscardFunc: navigateToListPage,
  };

  const auditLogDataFun = (content: any, isSftp: boolean) => {
    const diffJsonData: any = [];
    let diffData: any;

    if (isSftp) {
      diffData = JsonDiff(
        content?.["previousData"]?.["settings"] || {},
        content?.["currentData"]?.["Settings"] || {},
        [
          "TenantId",
          "createdAt",
          "updatedAt",
          "CreatedAt",
          "UpdatedAt",
          "DeletedBy",
          "DeletedAt",
          "DOB",
          "MobileWork",
        ]
      );
    } else {
      diffData = JsonDiff(
        content["previousActivity"] || {},
        content["currentActivity"] || {},
        [
          "TenantId",
          "tenantId",
          "rank",
          "vendorId",
          "createdAt",
          "updatedAt",
          "CreatedAt",
          "UpdatedAt",
          "DeletedBy",
          "deletedAt",
          "deletedBy",
          "DeletedAt",
          "MobileWork",
        ]
      );
    }

    if (typeof diffData === "object") {
      let a: any = [];
      let b: any = [];

      Object.keys(diffData).forEach((key: string) => {
        let finalKey = camelToNormalCase(key);

        if (key.includes("cecbems")) {
          finalKey = finalKey.replace("Cecbems", "CAPCE");
        }

        if (key.includes("countryCode")) {
          finalKey = finalKey.replace("Country Code", "Region");
        }

        if (key.includes("programType")) {
          finalKey = finalKey.replace("Program Type", "Program Mode");
        }

        if (key.includes("acpeupn")) {
          finalKey = finalKey.replace("Acpeupn", "ACPEUAN");
        }

        if (diffData[key].includes("has changed")) {
          a = diffData[key].split(`" to "`);
          let parent: any = [];
          parent = a[0].split(" ");
          parent = parent[0].split(".");
          let parentIndex = 1;
          while (!isNaN(Number(parent[parent.length - parentIndex]))) {
            parent.pop();
            parentIndex++;
          }
          b = a[0].split(" from ");

          if (
            key.includes("accreditationExpiryDate") ||
            key.includes("programExpiryDate") ||
            key.includes("programReleaseDate")
          ) {
            a[1] = a?.[1].split(" ")[0];
            b[1] = b[1].split(" ")[0];
          }

          if (
            diffData[key].includes("Date") ||
            diffData[key].includes("Expiration")
          ) {
            if (
              a?.[1].replace(/"/g, "").substring(0, 10) !==
              b?.[1].replace(/"/g, "").substring(0, 10)
            ) {
              if (
                !(parent[parent.length - 1] === "learner" && finalKey === "Dob")
              ) {
                diffJsonData.push({
                  key: finalKey,
                  currentData: a?.[1].split(" ")[0],
                  previousData: b?.[1].split(" ")[0],
                });
              }
            }
          } else {
            if (
              !(parent[parent.length - 1] === "learner" && finalKey === "Dob")
            ) {
              diffJsonData.push({
                key:
                  finalKey === "Code" || Number(finalKey)
                    ? camelToNormalCase(parent[parent.length - 1]) +
                      "[" +
                      finalKey +
                      "]"
                    : finalKey,
                currentData: a?.[1],
                previousData: b?.[1],
              });
            }
          }
        } else {
          a = diffData[key].split("value is");
          let parent: any = [];
          parent = a[0].split(" ");
          parent = parent[0].split(".");
          let parentIndex = 1;
          while (!isNaN(Number(parent[parent.length - parentIndex]))) {
            parent.pop();
            parentIndex++;
          }
          if (
            a?.[1].replace(/"/g, "").trim() !== "" &&
            a?.[1].replace(/"/g, "").trim() !== "N/A" &&
            a?.[1].replace(/"/g, "").trim() !== "undefined"
          ) {
            if (
              !(parent[parent.length - 1] === "learner" && finalKey === "Dob")
            ) {
              diffJsonData.push({
                key:
                  finalKey === "Code" || Number(finalKey)
                    ? camelToNormalCase(parent[parent.length - 1]) +
                      "[" +
                      finalKey +
                      "]"
                    : finalKey,
                currentData:
                  diffData[key].includes("Date") ||
                  diffData[key].includes("Expiration")
                    ? a?.[1].substring(1, 12)
                    : a?.[1],
                previousData: "",
              });
            }
          }
        }
      });
    }

    setAuditLogData({
      diffData: diffJsonData,
    });
  };

  const vendorLogDataFunc = (content: any) => {
    setVendorLogErrorData({ errorData: content.error });
  };

  const updateReportLogFilter = (filters: any) => {
    Object.keys(filters)?.map((key: any) => {
      if (Array.isArray(filters[key])) {
        if (key === "classifications") {
          filters[key] = findClassificationNames(filters[key]);
        } else if (key === "certificates") {
          filters[key] = findCertificatesNames(filters[key]);
        } else if (key === CUSTOM_REPORT_CONSTANTS.errorFields) {
          filters[key] = getErrorTypeName(
            filters[key],
            filters[CUSTOM_REPORT_CONSTANTS.errorType]
          )?.join(", ");
        } else {
          filters[key] = filters[key]?.join(",");
        }
      }
    });
    return filters;
  };

  const findClassificationNames = (classificationCodes: any[]) => {
    const filteredClassifications: any = classifications?.filter(
      (classification: any) =>
        classificationCodes.includes(classification?.code)
    );
    const classificationNames = filteredClassifications.map(
      (classification: any) => classification?.name
    );
    return classificationNames?.join(",");
  };

  const findCertificatesNames = (certificatesCodes: any[]) => {
    const filteredCertificates: any = certificates?.filter(
      (certificateTemplates: any) =>
        certificatesCodes.includes(certificateTemplates?.code)
    );
    const certificatesNames = filteredCertificates.map(
      (certificateTemplates: any) => certificateTemplates?.name
    );
    return certificatesNames?.join(",");
  };

  const [reportLogContent, setReportLogContent] = useState<any>(null);
  const reportLogDataFn = (content: any) => {
    if (content?.currentData?.filters?.filters) {
      content.currentData.filters.filters = updateReportLogFilter(
        content.currentData.filters.filters
      );
    }

    if (content?.previousData?.filters?.filters) {
      content.previousData.filters.filters = updateReportLogFilter(
        content.previousData.filters.filters
      );
    }

    if (content?.currentData?.reportDetails?.filters) {
      content.currentData.reportDetails.filters = updateReportLogFilter(
        content.currentData.reportDetails.filters
      );
    }

    if (content?.previousData?.reportDetails?.filters) {
      content.previousData.reportDetails.filters = updateReportLogFilter(
        content.previousData.reportDetails.filters
      );
    }

    setReportLogContent(content);
  };
  const handleCloseReportLogModal = () => undefined;

  const ref = useRef(null);
  const [width, setWidth] = useState("");

  const scrollref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (scrollref?.current?.scrollTo) {
      scrollref.current.scrollTo(0, 0);
    }
  }, [contents]);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      // @ts-ignore
      setWidth(ref.current ? ref.current.offsetWidth : 0);
    });
    window.addEventListener("mousemove", () => {
      // @ts-ignore
      setWidth(ref.current ? ref.current.offsetWidth : 0);
    });
  }, [ref.current]);

  const s1 = document.getElementById("Select1");
  const s2 = document.getElementById("Select2");

  function select_scroll_1() {
    if (s1 !== null && s2 !== null) {
      s2.scrollLeft = s1.scrollLeft;
    }
  }
  function select_scroll_2() {
    if (s1 !== null && s2 !== null) {
      s1.scrollLeft = s2.scrollLeft;
    }
  }

  if (s1 !== null && s2 !== null) {
    s1.addEventListener("scroll", select_scroll_1, false);
    s2.addEventListener("scroll", select_scroll_2, false);
  }

  const codeType = typeof cme_pageID !== undefined &&
  cme_pageID ===
    CONSTANTS.ACCREDITATION_PAGEID
    ? t<string>("accreditationCode")
    : cme_pageID ===
      CONSTANTS.CERTIFICATE_PAGEID
    ? t<string>("certificateCode")
    : t<string>("code")

  return (
    <CustomisedTableWrapper>
      {children}
      <div
        className={
          cme_pageID === CONSTANTS.PREDEFINED_REPORT ||
          cme_pageID === CONSTANTS.CUSTOM_REPORT_PAGEID ||
          cme_pageID === CONSTANTS.SUCCESS_ACTIVITY
            ? "match-width-wrapper"
            : ""
        }
      >
        <div
          className={
            cme_pageID === CONSTANTS.PREDEFINED_REPORT ||
            cme_pageID === CONSTANTS.CUSTOM_REPORT_PAGEID ||
            cme_pageID === CONSTANTS.SUCCESS_ACTIVITY
              ? "scroll-bar-div"
              : "d-none"
          }
          ref={
            cme_pageID === CONSTANTS.PREDEFINED_REPORT ||
            cme_pageID === CONSTANTS.CUSTOM_REPORT_PAGEID ||
            cme_pageID === CONSTANTS.SUCCESS_ACTIVITY
              ? scrollref
              : null
          }
          id="Select1"
          aria-hidden={true}
        >
          <div className="top-scroll" aria-hidden={true} style={{ width }}>
            &nbsp;
          </div>
        </div>
        <table className={loader} id="Select2" tabIndex={0}>
          <thead id="Select3">
            <tr>
              {/*Blank header for check box  */}
              {isCheckBox && actions?.delete && (
                <th key={"index"} scope="col" className="p-0">
                  <div className="aui-th wbg">{""}</div>
                </th>
              )}
              {/*Blank header for check box  */}
              {columns.map((column) => {
                if (!column?.isChecked) return null;
                let shouldDisplayColumn =
                  /* if the column is a "code" column and not "name" column */
                  ["code"].includes(column?.propertyName) ||
                  !["name"].includes(column?.propertyName) ||
                  /* if the column combines with any other column */
                  (column?.combineWithColumnName != null &&
                    column?.propertyName !== column?.combineWithColumnName);
                isColumnJoin =
                  column?.combineWithColumnName != null &&
                  column?.propertyName !== column?.combineWithColumnName;
                if (
                  !shouldDisplayColumn &&
                  cme_pageID === CONSTANTS.USERLIST_PAGEID
                ) {
                  shouldDisplayColumn = ["name"].includes(column?.propertyName);
                }
                return shouldDisplayColumn ? (
                  <th key={column?.name} scope="col">
                    <div className="aui-th">{column?.name}</div>
                  </th>
                ) : null;
              })}
              {(actions?.add ||
                actions?.edit ||
                actions?.view ||
                actions?.download) && (
                <th scope="col">
                  <div className="aui-th">Action</div>
                </th>
              )}
            </tr>
          </thead>

          {records?.length === 0 && !tableLoader ? (
            <tbody>
              <tr className="aui-status-table-emptystate aui-table-empty mb-0">
                <td
                  colSpan={
                    isColumnJoin ? columns.length + 1 : columns.length + 2
                  }
                >
                  <div className="aui-table-no-data d-flex flex-column justify-content-center align-items-center">
                    <img src="/images/no-recs.png" alt="No Date" />
                    <h1 className="mb-0">{t<string>("noDataFound")}</h1>
                  </div>
                </td>
              </tr>
            </tbody>
          ) : (
            <>
              <tbody
                className={
                  isCheckBox && !hideVendorFilter
                    ? "chkbox-ml " + cme_pageID?.toLowerCase()
                    : cme_pageID?.toLowerCase()
                }
                ref={ref}
              >
                {records.map((content: any, index: number) => {
                  const showActionsColumn =
                    actions?.add ||
                    actions?.edit ||
                    actions?.view ||
                    actions?.download;
                  if (
                    typeof cme_pageID === "undefined" ||
                    cme_pageID === CONSTANTS.ACCREDITATION_PAGEID ||
                    cme_pageID === CONSTANTS.SUCCESS_ACTIVITY ||
                    cme_pageID === CONSTANTS.ERRORLIST_PAGE ||
                    cme_pageID === CONSTANTS.USERLIST_PAGEID ||
                    cme_pageID === CONSTANTS.PREDEFINED_REPORT ||
                    cme_pageID === CONSTANTS.SCHEDULER_LIST_PAGEID ||
                    cme_pageID === CONSTANTS.CERTIFICATE_PAGEID
                  ) {
                    return (
                      <tr
                        key={index}
                        className={classNames(activeClass(content), {
                          "disable-cell":
                            content?.status === CONSTANTS.SCHEDULE_DELETED &&
                            cme_pageID === CONSTANTS.SCHEDULER_LIST_PAGEID,
                        })}
                      >
                        {/* checkbox start */}
                        {isCheckBox && actions?.delete && (
                          <td key={"index"} className="notd p-0">
                            <SelectAll
                              id={content["id"]}
                              checkBxList={checkBxList}
                              setCheckBxList={setCheckBxList}
                              MsgBody={MsgBody}
                            />
                          </td>
                        )}
                        {/* checkbox end */}

                        {columns.map((column) => {
                          if (!column?.isChecked) return null;

                          const isCombinedColumn =
                            column?.combineWithColumnName != null &&
                            /* safety check */
                            column?.propertyName !==
                              column?.combineWithColumnName;

                          if (
                            ["code"].includes(column?.propertyName) ||
                            isCombinedColumn ||
                            (cme_pageID === CONSTANTS.USERLIST_PAGEID &&
                              ["name"].includes(column?.propertyName))
                          ) {
                            return (                             
                              <td
                                key={column?.name}
                                data-title={column?.name}
                                className="table-col1"
                              >
                                <div className="aui-td">
                                  <span className="font-weight-bold">
                                    {typeof cme_pageID !== undefined &&
                                    cme_pageID === CONSTANTS.SUCCESS_ACTIVITY &&
                                    column?.propertyName === "firstName"
                                      ? `${t<string>("firstName")}:`
                                      : ""}
                                  </span>
                                  <div>
                                    {stripHtml(content["name"]) ??
                                      getByDot(content, column?.propertyName)}
                                  </div>
                                  <div
                                    className={
                                      cme_pageID === CONSTANTS.USERLIST_PAGEID
                                        ? `d-none`
                                        : ``
                                    }
                                  >
                                    <span className="font-weight-bold">
                                      {cme_pageID === CONSTANTS.SUCCESS_ACTIVITY ?
                                        column?.name === "Program" ? `${t<string>("code")}: `
                                        : column?.name === "Name" ? `${t<string>("lastName")}: `
                                          : ""
                                        : codeType + ": "}
                                    </span>
                                    {stripHtml(content["code"]) ??
                                      content[
                                        column?.combineWithColumnName ?? ""
                                      ]}
                                  </div>
                                  {content.isSession &&
                                    column?.combineWithColumnName ===
                                      "programCode" && (
                                      <div className="speciality-tag-session">
                                        <aui-tags
                                          numberoftags="Speciality Conference (ACPE)"
                                          size="small"
                                          type="clients"
                                        ></aui-tags>
                                      </div>
                                    )}
                                </div>
                              </td>
                            );
                          } else if (
                            !["code", "name"].includes(column?.propertyName)
                          ) {
                            if (column?.name === "Program") {
                              return (
                                <td
                                  key={column?.name}
                                  data-title={column?.name}
                                >
                                  <div className="aui-td">
                                    <div>
                                      {content["programName"]}
                                      <br />
                                      <label>
                                        <b>{t<string>("code")} </b>:{" "}
                                      </label>{" "}
                                      {content["programCode"]}
                                      {content.isSession && (
                                        <div className="speciality-tag-session">
                                          <aui-tags
                                            numberoftags="Speciality Conference (ACPE)"
                                            size="small"
                                            type="clients"
                                          ></aui-tags>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </td>
                              );
                            }
                            if (column?.name === "Program Info") {
                              return (
                                <td
                                  key={column?.name}
                                  data-title={column?.name}
                                >
                                  <div className="aui-td">
                                    <div>
                                      <label>{t<string>("name")} : </label>{" "}
                                      {content["programName"]}
                                      <br />
                                      <label>{t<string>("code")} : </label>{" "}
                                      {content["programCode"]}
                                    </div>
                                  </div>
                                </td>
                              );
                            }
                            if (
                              typeof getByDot(content, column?.propertyName) ===
                              "boolean"
                            ) {
                              return (
                                <td
                                  key={column?.name}
                                  data-title={column?.name}
                                >
                                  <div className="aui-td">
                                    <div>{mapBoolean(content, column)}</div>
                                  </div>
                                </td>
                              );
                            } else {
                              let propertyValue = stripHtml(
                                getByDot(content, column?.propertyName)
                              );

                              let isSession;
                              if (column?.propertyName === "programName") {
                                isSession = stripHtml(
                                  getByDot(content, "isSession")
                                );
                              }

                              if (
                                propertyValue !== "" &&
                                propertyValue !== undefined
                              ) {
                                if (column?.isDate) {
                                  /* Try to evaluate using the "dateMethod" else default to datefns "MM-dd-yyyy" */
                                  if (column?.dateMethod) {
                                    propertyValue = column?.dateMethod(
                                      getByDot(content, column?.propertyName),
                                      "",
                                      "",
                                      "locale"
                                    );
                                  } else {
                                    propertyValue = DateWithTimeByLocale(
                                      getByDot(content, column?.propertyName),
                                      "",
                                      ""
                                    );
                                  }
                                }
                              } else if (column?.propertyName === "msg") {
                                return (
                                  <td
                                    key={column?.name}
                                    data-title={column?.name}
                                  >
                                    <div className="aui-td">
                                      {getByDot(content, "error.details")
                                        ?.map(
                                          (ed: { message: string }) =>
                                            ed.message
                                        )
                                        ?.join(", ") ?? "N/A"}
                                    </div>
                                  </td>
                                );
                              } else {
                                propertyValue = "N/A";
                              }

                              if (
                                content["program"]?.["certificate"]?.[
                                  "certificateTemplateID"
                                ] === "1" &&
                                content["program"]?.["meta"]?.["moc"] &&
                                column?.name === "Completion Date" &&
                                cme_pageID === CONSTANTS.PREDEFINED_REPORT
                              ) {
                                propertyValue = DateFullFormat(
                                  getByDot(content, column?.propertyName),
                                  "MM/DD/YYYY"
                                );
                              }

                              if (
                                column?.name === "Created by" &&
                                cme_pageID === CONSTANTS.SCHEDULER_LIST_PAGEID
                              ) {
                                propertyValue =
                                  content["userFirstName"] +
                                  " " +
                                  content["userLastName"];
                              }

                              if (column?.dateFormat != null) {
                                propertyValue = DateFullFormat(
                                  getByDot(content, column?.propertyName),
                                  column?.dateFormat
                                );
                              }

                              if (
                                propertyValue === undefined ||
                                propertyValue === null
                              ) {
                                propertyValue = "N/A";
                              }
                              return (
                                <td
                                  key={column?.name}
                                  data-title={column?.name}
                                >
                                  <div className={"aui-td"}>
                                    <div>
                                      {propertyValue}
                                      {isSession && (
                                        <div className="speciality-tag">
                                          <aui-tags
                                            crossicon={false}
                                            numberoftags="Speciality Conference (ACPE)"
                                            size="small"
                                            type="clients"
                                          ></aui-tags>
                                        </div>
                                      )}
                                      {column?.name === "Title" &&
                                        content?.status ===
                                          CONSTANTS.SCHEDULE_DELETED &&
                                        cme_pageID ===
                                          CONSTANTS.SCHEDULER_LIST_PAGEID && (
                                          <div className="expired-schedule-list-label">
                                            Expired
                                            <span className="pl-1">
                                              <AuiIcon
                                                elementObject={{
                                                  svgclass: "aui-icon-alert",
                                                  pathclass: "aui-path-alert",
                                                  icon: "alert",
                                                  svgwidth: "12",
                                                  svgheight: "12",
                                                }}
                                              ></AuiIcon>
                                            </span>
                                          </div>
                                        )}
                                    </div>
                                  </div>
                                </td>
                              );
                            }
                          }
                          return null;
                        })}

                        {showActionsColumn && (
                          <Action
                            actions={actions}
                            content={content}
                            id={content.id}
                            iconTextOnly={iconTextOnly}
                            editContent={editContent}
                            onStatusToggle={onStatusToggle}
                            downloadContent={downloadContent}
                            columns={columns}
                            viewContent={viewContent}
                            index={index}
                            isVisibleDelBtn={checkBxList?.includes(
                              content["id"]
                            )}
                            deleteRecords={deleteRecords}
                            MsgBody={MsgBody}
                            cme_pageID={cme_pageID}
                            isToggleAllowed={
                              !(
                                content?.status ===
                                  CONSTANTS.SCHEDULE_DELETED &&
                                cme_pageID === CONSTANTS.SCHEDULER_LIST_PAGEID
                              )
                            }
                          />
                        )}
                      </tr>
                    );
                  } else if (
                    typeof cme_pageID !== "undefined" &&
                    cme_pageID === CONSTANTS.AUDITLOG_PAGEID
                  ) {
                    return (
                      <tr
                        key={content.id ?? content.code + index ?? index}
                        className="aui-table-status-red"
                      >
                        {columns.map((column) => {
                          if (!column?.isChecked) return null;

                          const isCombinedColumn =
                            column?.combineWithColumnName != null &&
                            /* safety check */
                            column?.propertyName !==
                              column?.combineWithColumnName;
                          if (
                            ["actorId"].includes(column?.propertyName) ||
                            isCombinedColumn
                          ) {
                            return (
                              <td
                                key={column?.name}
                                data-title={column?.name}
                                className="table-col1"
                              >
                                <div className="aui-td">
                                  <div>{content["actorName"]}</div>
                                  <div>
                                    <span className="lable-padding-right">
                                      ID :
                                    </span>
                                    {content["actorId"]}
                                  </div>
                                </div>
                              </td>
                            );
                          } else if (
                            !["actorId", "actorName"].includes(
                              column?.propertyName
                            )
                          ) {
                            if (column?.name === "Details") {
                              return (
                                <td
                                  key={column?.name}
                                  data-title={column?.name}
                                >
                                  <div className="aui-td">
                                    <div>
                                      <label className="lable-padding-right">
                                        {t<string>("logid")} :{" "}
                                      </label>
                                      {content["id"]}
                                      <br />
                                      <label className="lable-padding-right">
                                        {t<string>("entity")} :{" "}
                                      </label>
                                      {content["targetType"]}
                                      <br />
                                      <label className="lable-padding-right">
                                        {t<string>("target")} :{" "}
                                      </label>
                                      {content["targetId"]}
                                      <br />
                                      <label className="lable-padding-right">
                                        {t<string>("date")}:{" "}
                                      </label>
                                      {DateWithTimeByLocale(
                                        content["createdAt"],
                                        "",
                                        ""
                                      )}
                                      <br />
                                    </div>
                                  </div>
                                </td>
                              );
                            }
                            if (column?.name === "Change Description") {
                              let messageContent = "";
                              const isSftp = content["targetType"] === "SFTP";

                              switch (content["action"]) {
                                case "ADD":
                                  messageContent = `${content["targetType"]} is added`;
                                  break;
                                case "UPDATE":
                                  messageContent = `${content["targetType"]} is updated`;
                                  break;
                              }

                              if (
                                content["currentData"] === null &&
                                content["previousData"] === null
                              ) {
                                return (
                                  <td
                                    key={column?.name}
                                    data-title={column?.name}
                                  >
                                    <div className="aui-td">
                                      <div className="col-md-7">
                                        <p> {content["changeDescription"]}</p>
                                      </div>
                                    </div>
                                  </td>
                                );
                              } else {
                                return (
                                  <td
                                    key={column?.name}
                                    data-title={column?.name}
                                  >
                                    <div className="aui-td">
                                      <div className="col-md-7">
                                        <p> {messageContent}</p>
                                      </div>
                                      <div className="col-md-5">
                                        <p
                                          className="sftp-dot-style"
                                          data-toggle={"modal"}
                                          data-target={"#auditLogDiffModal"}
                                          onClick={() =>
                                            auditLogDataFun(content, isSftp)
                                          }
                                        >
                                          <AuiIcon
                                            elementObject={{
                                              svgclass:
                                                "aui-icon-ellipsis text-primary",
                                              pathclass: "aui-path-ellipsis",
                                              icon: "ellipsis",
                                              svgwidth: "20",
                                              svgheight: "20",
                                            }}
                                          ></AuiIcon>
                                        </p>
                                      </div>
                                    </div>
                                  </td>
                                );
                              }
                            }
                          }
                          return null;
                        })}
                      </tr>
                    );
                  } else if (
                    typeof cme_pageID !== "undefined" &&
                    cme_pageID === CONSTANTS.BATCHLOG_PAGEID
                  ) {
                    return (
                      <tr
                        key={content.id ?? content.code + "" + index}
                        className={classNames({
                          "aui-table-status-red": content?.status === "FAILED",
                          "aui-table-status-grey": content?.status !== "FAILED",
                        })}
                      >
                        {columns.map((column, ind) => {
                          if (!column?.isChecked) return null;

                          if (["id"].includes(column?.propertyName)) {
                            return (
                              <td
                                key={column?.name}
                                data-title={column?.name}
                                className="table-col1"
                              >
                                <div className="aui-td">
                                  <div>{content["id"]}</div>
                                </div>
                              </td>
                            );
                          } else {
                            if (column?.propertyName === "fileName") {
                              return (
                                <React.Fragment
                                  key={
                                    (content.id ?? content.code + index) +
                                    ind +
                                    "" +
                                    +column?.name
                                  }
                                >
                                  <td
                                    key={column?.name}
                                    data-title={column?.name}
                                    className="show-desktop"
                                  >
                                    <div className="aui-td">
                                      <div>
                                        <label className="lable-padding-right">
                                          {t<string>("fileName")} :{" "}
                                        </label>
                                        {content["meta"]
                                          ? content["meta"]["filename"]
                                          : "N/A"}
                                        <br />
                                        <label className="lable-padding-right">
                                          {t<string>("date")} :{" "}
                                        </label>
                                        {content["updatedAt"] &&
                                          DateWithTimeByLocale(
                                            content["updatedAt"],
                                            "",
                                            ""
                                          )}
                                        <br />
                                        <label className="lable-padding-right">
                                          {t<string>("activityExported")} :{" "}
                                        </label>
                                        {content["activityCount"]}
                                      </div>
                                    </div>
                                  </td>
                                  {/* small device */}

                                  <td
                                    key={column?.name + "-m"}
                                    data-title={column?.name}
                                    className={
                                      content["meta"] &&
                                      content["meta"]["filename"]
                                        ? "show-mobile"
                                        : "show-mobile pad12"
                                    }
                                  >
                                    <div className="aui-td">
                                      <div>
                                        {content["meta"]
                                          ? content["meta"]["filename"]
                                          : "N/A"}
                                      </div>
                                    </div>
                                  </td>

                                  <td
                                    key={"date"}
                                    data-title={t<string>("date")}
                                    className="show-mobile"
                                  >
                                    <div className="aui-td">
                                      <div>
                                        {content?.updatedAt &&
                                          DateWithTimeByLocale(
                                            content?.updatedAt,
                                            "",
                                            ""
                                          )}
                                      </div>
                                    </div>
                                  </td>

                                  <td
                                    key={"activityExported"}
                                    data-title={t<string>("activityExported")}
                                    className="show-mobile"
                                  >
                                    <div className="aui-td">
                                      <div> {content?.activityCount}</div>
                                    </div>
                                  </td>
                                </React.Fragment>
                              );
                            } else if (column?.name === "Status") {
                              return (
                                <React.Fragment
                                  key={
                                    (content.id ?? content.code + index) +
                                    ind +
                                    "" +
                                    +column?.name
                                  }
                                >
                                  <td
                                    data-title={column?.name}
                                    className="col-lg-2"
                                  >
                                    <div className="aui-td">
                                      <div>
                                        {content["status"]}
                                        <br />
                                      </div>
                                    </div>
                                  </td>

                                  {showActionsColumn && (
                                    <Action
                                      actions={actions}
                                      content={content}
                                      iconTextOnly={iconTextOnly}
                                      editContent={editContent}
                                      onStatusToggle={onStatusToggle}
                                      downloadContent={downloadContent}
                                      columns={columns}
                                      viewContent={viewContent}
                                      index={index}
                                      cme_pageID={CONSTANTS.BATCHLOG_PAGEID}
                                      isVisibleDelBtn={checkBxList?.includes(
                                        content["id"]
                                      )}
                                      deleteRecords={deleteRecords}
                                      MsgBody={MsgBody}
                                    />
                                  )}
                                </React.Fragment>
                              );
                            }
                          }

                          return null;
                        })}
                      </tr>
                    );
                  } else if (
                    typeof cme_pageID !== "undefined" &&
                    (cme_pageID === CONSTANTS.REPORTLOG_PAGEID ||
                      cme_pageID === CONSTANTS.CUSTOMREPORTLOG_PAGEID)
                  ) {
                    return (
                      <tr
                        key={content.id ?? content.code + "" + index}
                        className={classNames({
                          "aui-table-status-red":
                            /* if status field does not exist or is truthy */
                            content?.status === "FAILED",
                          "aui-table-status-grey": content?.status !== "FAILED",
                        })}
                      >
                        {columns.map((column, ind) => {
                          if (!column?.isChecked) return null;

                          if (column?.propertyName === "actorFirstName") {
                            return (
                              <React.Fragment
                                key={
                                  (content.id ?? content.code + index) +
                                  ind +
                                  "" +
                                  +column?.name
                                }
                              >
                                <td
                                  key={column?.name}
                                  data-title={column?.name}
                                  className="show-desktop"
                                >
                                  <div className="aui-td">
                                    <div>
                                      <label className="lable-padding-right">
                                        {t<string>("firstName")} :{" "}
                                      </label>
                                      {content["actorFirstName"]}
                                      <br />
                                      <label className="lable-padding-right">
                                        {t<string>("lastName")} :{" "}
                                      </label>
                                      {content["actorLastName"]}
                                      <br />
                                      <label className="lable-padding-right">
                                        {t<string>("email")} :{" "}
                                      </label>
                                      {content["actorEmail"]}
                                    </div>
                                  </div>
                                </td>
                                {/* small device */}

                                <td
                                  key={"firstName"}
                                  data-title={t<string>("firstName")}
                                  className="show-mobile"
                                >
                                  <div className="aui-td">
                                    <div>{content["actorFirstName"]}</div>
                                  </div>
                                </td>

                                <td
                                  key={"lastName"}
                                  data-title={t<string>("lastName")}
                                  className="show-mobile"
                                >
                                  <div className="aui-td">
                                    <div>{content["actorLastName"]}</div>
                                  </div>
                                </td>

                                <td
                                  key={"email"}
                                  data-title={t<string>("email")}
                                  className="show-mobile"
                                >
                                  <div className="aui-td">
                                    <div>{content["actorEmail"]}</div>
                                  </div>
                                </td>
                              </React.Fragment>
                            );
                          } else if (
                            column?.propertyName === "changeDescription"
                          ) {
                            const messageContent =
                              content[column?.propertyName];

                            const reUpdated = /schedule.*update/i;
                            const reCreated = /schedule.*create/i;
                            const reModified = /Report Filter Modified/i;
                            return (
                              <td key={column?.name} data-title={column?.name}>
                                <div className="aui-td">
                                  <div className="col pl-0">
                                    <p> {messageContent}</p>
                                  </div>
                                  {reUpdated.test(messageContent) ||
                                  reCreated.test(messageContent) ||
                                  reModified.test(messageContent) ? (
                                    <div className="col-12 pl-0 col-sm-3 pr-0">
                                      <p
                                        className="sftp-dot-style"
                                        data-toggle={"modal"}
                                        data-target={"#reportLogDiffModal"}
                                        onClick={() => reportLogDataFn(content)}
                                      >
                                        <AuiIcon
                                          elementObject={{
                                            svgclass:
                                              "aui-icon-ellipsis text-primary",
                                            pathclass: "aui-path-ellipsis",
                                            icon: "ellipsis",
                                            svgwidth: "20",
                                            svgheight: "20",
                                          }}
                                        ></AuiIcon>
                                      </p>
                                    </div>
                                  ) : null}
                                </div>
                              </td>
                            );
                          } else {
                            let propertyValue = stripHtml(
                              getByDot(content, column?.propertyName)
                            );
                            if (column?.isDate) {
                              /* Try to evaluate using the "dateMethod" else default to datefns "MM-dd-yyyy" */
                              propertyValue = DateWithTimeByLocale(
                                getByDot(content, column?.propertyName),
                                "",
                                ""
                              );
                            }
                            return (
                              <td key={column?.name} data-title={column?.name}>
                                <div className="aui-td">
                                  <div>{propertyValue}</div>
                                </div>
                              </td>
                            );
                          }
                        })}
                        {showActionsColumn && (
                          <Action
                            actions={actions}
                            content={content}
                            iconTextOnly={iconTextOnly}
                            editContent={editContent}
                            onStatusToggle={onStatusToggle}
                            downloadContent={downloadContent}
                            columns={columns}
                            viewContent={viewContent}
                            index={index}
                            isVisibleDelBtn={checkBxList?.includes(
                              content["id"]
                            )}
                            deleteRecords={deleteRecords}
                            MsgBody={MsgBody}
                            cme_pageID={cme_pageID}
                          />
                        )}
                      </tr>
                    );
                  } else if (
                    typeof cme_pageID !== "undefined" &&
                    cme_pageID === CONSTANTS.VENDORLOG_PAGEID
                  ) {
                    return (
                      <tr
                        key={content.id ?? content.code + "" + index}
                        className={classNames({
                          "aui-table-status-red":
                            /* if status field does not exist or is truthy */
                            content?.status === "FAIL",
                          "aui-table-status-grey": content?.status !== "FAIL",
                        })}
                      >
                        {columns.map((column, ind) => {
                          if (!column?.isChecked) return null;

                          if (column?.propertyName === "fileDetails") {
                            return (
                              <React.Fragment
                                key={
                                  (content.id ?? content.code + index) +
                                  ind +
                                  "" +
                                  +column?.name
                                }
                              >
                                <td
                                  key={column?.name}
                                  data-title={column?.name}
                                  className="show-desktop"
                                >
                                  <div className="aui-td">
                                    <div>
                                      <label className="lable-padding-right">
                                        {t<string>("fileName")} :{" "}
                                      </label>
                                      {content["fileName"]}
                                      <br />
                                      <label className="lable-padding-right">
                                        {t<string>("date")} :{" "}
                                      </label>
                                      {DateWithTimeByLocale(
                                        content["updatedAt"],
                                        "",
                                        ""
                                      )}
                                      <br />
                                      <label className="lable-padding-right">
                                        {t<string>("activityImported")} :{" "}
                                      </label>
                                      {content["importedActivity"]}
                                    </div>
                                  </div>
                                </td>
                                {/*Below is For Small Devices */}
                                <td
                                  key={"fileName"}
                                  data-title={t<string>("fileName")}
                                  className="show-mobile"
                                >
                                  <div className="aui-td">
                                    <div>{content["fileName"]}</div>
                                  </div>
                                </td>
                                <td
                                  key={"date"}
                                  data-title={t<string>("date")}
                                  className="show-mobile"
                                >
                                  <div className="aui-td">
                                    <div>
                                      {DateWithTimeByLocale(
                                        content["updatedAt"],
                                        "",
                                        ""
                                      )}
                                    </div>
                                  </div>
                                </td>
                                <td
                                  key={"email"}
                                  data-title={t<string>("activityImported")}
                                  className="show-mobile"
                                >
                                  <div className="aui-td">
                                    <div> {content["importedActivity"]}</div>
                                  </div>
                                </td>
                              </React.Fragment>
                            );
                          } else if (column?.name === "Status") {
                            if (content["status"] === "SUCCESS") {
                              return (
                                <td
                                  key={column?.name}
                                  data-title={column?.name}
                                >
                                  <div className="aui-td">
                                    <p> {content["status"]}</p>
                                  </div>
                                </td>
                              );
                            } else {
                              return (
                                <td
                                  key={column?.name}
                                  data-title={column?.name}
                                >
                                  <div className="aui-td">
                                    <div
                                      className={classNames({
                                        "aui-red": content?.status === "FAIL",
                                      })}
                                    >
                                      {" "}
                                      {content["status"]}
                                    </div>
                                    <br />
                                    <div
                                      className="sftp-dot-style"
                                      data-toggle={"modal"}
                                      data-target={"#vendorLogDiffModal"}
                                      onClick={() => vendorLogDataFunc(content)}
                                    >
                                      <AuiIcon
                                        elementObject={{
                                          svgclass:
                                            "aui-icon-ellipsis text-primary",
                                          pathclass: "aui-path-ellipsis",
                                          icon: "ellipsis",
                                          svgwidth: "20",
                                          svgheight: "20",
                                        }}
                                      ></AuiIcon>
                                    </div>
                                  </div>
                                </td>
                              );
                            }
                          } else {
                            let propertyValue = stripHtml(
                              getByDot(content, column?.propertyName)
                            );
                            if (column?.isDate) {
                              /* Try to evaluate using the "dateMethod" else default to datefns "MM-dd-yyyy" */
                              propertyValue = DateWithTimeByLocale(
                                getByDot(content, column?.propertyName),
                                "",
                                ""
                              );
                            }
                            return (
                              <td key={column?.name} data-title={column?.name}>
                                <div className="aui-td">
                                  <div>{propertyValue}</div>
                                </div>
                              </td>
                            );
                          }
                        })}
                      </tr>
                    );
                  } else if (
                    typeof cme_pageID !== "undefined" &&
                    cme_pageID === CONSTANTS.CUSTOM_REPORT_PAGEID
                  ) {
                    return (
                      <tr
                        key={content.activityId}
                        className="aui-table-status-success"
                      >
                        {columns.map((column, index) => {
                          return (
                            <React.Fragment key={column.name}>
                              <td
                                data-title={column?.name}
                                className={
                                  column?.propertyName ===
                                  "learnerAddressLineInfo"
                                    ? "show-desktop custom-report-row"
                                    : "custom-report-row"
                                }
                                key={column?.propertyName}
                              >
                                {column?.propertyName ===
                                "learnerAddressLineInfo" ? (
                                  getAddressInfo(content[column?.propertyName])
                                ) : (
                                  <div className="aui-td">
                                    {content[column?.propertyName] === null ||
                                    content[column?.propertyName] === "" ||
                                    content[column?.propertyName] === "null" ||
                                    content[column?.propertyName] === undefined
                                      ? "N/A"
                                      : content[column?.propertyName]}
                                  </div>
                                )}
                              </td>

                              {column?.propertyName ===
                                "learnerAddressLineInfo" &&
                                getAddressMobileView(
                                  content[column?.propertyName]
                                )}
                            </React.Fragment>
                          );
                        })}
                      </tr>
                    );
                  }
                  return null;
                })}
              </tbody>
              <tbody className={`scrollBody`}></tbody>
            </>
          )}
        </table>
        {cme_pageID === CONSTANTS.AUDITLOG_PAGEID && (
          <ModalPopupComp
            id="auditLogDiffModal"
            childrenHeader={<ModalHeader />}
            childrenBody={<AuditLogBody auditLogData={auditLogData} />}
            childrenBodyClass="table-wrapper-scroll-y my-custom-scrollbar"
            popupDiscardFunc={modalPopupProps.popupDiscardFunc}
          />
        )}
        {(cme_pageID === CONSTANTS.REPORTLOG_PAGEID ||
          cme_pageID === CONSTANTS.CUSTOMREPORTLOG_PAGEID) && (
          <>
            <ModalPopupComp
              id="reportLogDiffModal"
              childrenHeader={<ModalHeader component="ReportLog" />}
              childrenBody={
                reportLogContent ? (
                  <ReportLogDiff
                    currentData={reportLogContent?.currentData}
                    previousData={reportLogContent?.previousData}
                  />
                ) : (
                  <div />
                )
              }
              childrenBodyClass="table-wrapper-scroll-y my-custom-scrollbar"
              modalParent="show fade showModal"
              popupDiscardFunc={handleCloseReportLogModal}
              component="ReportLog"
            />
          </>
        )}
        {cme_pageID === CONSTANTS.VENDORLOG_PAGEID && (
          <ModalPopupComp
            id="vendorLogDiffModal"
            childrenHeader={
              <ModalHeader className="ml-3" component="VendorLog">
                Error Details
              </ModalHeader>
            }
            childrenBody={
              <VendorLogModalBody vendorLogErrorData={vendorLogErrorData} />
            }
            childrenBodyClass="table-wrapper-scroll-y my-custom-scrollbar"
            popupDiscardFunc={modalPopupProps.popupDiscardFunc}
            component="VendorLog"
          />
        )}
      </div>
    </CustomisedTableWrapper>
  );
};
export default CustomisedTable;
